import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseURL } from "../../Helper/config";
import axios from "axios";
import { getTagsByModuleName, tags } from "../Tags/Tags";

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: BaseURL }),
  tagTypes:  tags.map(tag => tag.tag),

  endpoints: (builder) => ({
    getSellerOrders: builder.query({
      query: (id) => `/get-order-by-single-seller/${id}`,
      providesTags: getTagsByModuleName("SellerOrders"),

    }),
    updateOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-orders/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: getTagsByModuleName("SellerOrders"),
    }),
    getBuyerOrder: builder.query({
      query: (id) => `/get-order-by-single-buyer/${id}`,
      providesTags: getTagsByModuleName("BuyerOrders"),
    }),
    getRiderOrder: builder.query({
      query: (id) => `/get-order-by-rider/${id}`,
    }),

    getSellerInfo: builder.query({
      query: (id) => `/sellerInfo/${id}`,
    }),
    updateHandle: builder.mutation({
      query: ({ id, data }) => ({
        url: `update-users/${id}`,
        method: "POST",
        body: data,
      }),
    }),
    updateSeller: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-become-seller/${id}`,
        method: "POST",
        body: data,
      }),
    }),
    AllCountry: builder.query({
      query: () => "/get-country",
    }),
    getCuisineByCountry: builder.query({
      query: (id) => `/get-cuisine-by-country/${id}`,
    }),
    getRegionByCountry: builder.query({
      query: (id) => `/get-reigion-by-country/${id}`,
    }),
    getCityByRegion: builder.query({
      query: (id) => `/get-city-by-reigion/${id}`,
    }),
    getThanaByRegion: builder.query({
      query: (id) => `/get-thana-by-region/${id}`,
    }),
    getBankList: builder.query({
      query: (type) => `get-banklist?type=${type}`,
    }),
    getRiderInfo: builder.query({
      query: (id) => `/riderInfo/${id}`,
    }),
    getAssignRider: builder.query({
      query: (id) => `/get-order-assign-request-by-rider/${id}`,
    }),
    getTransactionRider: builder.query({
      query: (id) => `/get-earning-rider/${id}`,
    }),
    getTransactionSeller: builder.query({
      query: (id) => `/get-earning-seller/${id}`,
    }),
    getOrderStatus: builder.query({
      query: () => `/get-orderStatus`,
    }),
    updateOrderByRider: builder.mutation({
      query: ({ orderId, data }) => ({
        url: `/order-updated-by-rider/${orderId}`,
        method: "POST",
        body: data,
      }),
      // invalidatesTags: getTagsByModuleName("SellerOrders"),
    }),
    addOrderTracking: builder.mutation({
      query: ({ data }) => ({
        url: `/create-orders-tacking/`,
        method: "POST",
        body: data,
      }),
    })

  }),
});
export const {
  useGetSellerOrdersQuery,
  useUpdateOrderMutation,
  useGetBuyerOrderQuery,
  useGetSellerInfoQuery,
  useUpdateHandleMutation,
  useUpdateSellerMutation,
  useAllCountryQuery,
  useGetCuisineByCountryQuery,
  useGetRegionByCountryQuery,
  useGetCityByRegionQuery,
  useGetBankListQuery,
  useGetRiderInfoQuery,
  useGetRiderOrderQuery,
  useGetAssignRiderQuery,
  useGetTransactionRiderQuery,
  useGetTransactionSellerQuery,
  useGetOrderStatusQuery,
  useGetThanaByRegionQuery,
  useAddOrderTrackingMutation,
  useUpdateOrderByRiderMutation






} = api;
