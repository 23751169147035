export const BaseURL = "https://api.ownfood.com.bd/api/v1";

// export const BaseURL = "http://localhost:5000/api/v1";
// export const BaseURL = "https://staging-api.ownfood.net/api/v1";

/*=======================FrontEnd Link ========================*/
// export const FrontEndLink = "https://ownfood.net/";
// export const FrontEndLink = "https://ownfood.net/";
export const FrontEndLink = "https://ownfood.com.bd/";
// export const FrontEndLink="http://localhost:3000/"
